<template>
  <div class="card bg-light shadow mt-3">
    <div class="card-body">
      <p>Fecha</p>
      <input type="date" v-model="nuevoGasto.fecha" class="form-control">
      <p>Concepto</p>
      <input type="text" v-model="nuevoGasto.concepto" class="form-control">
      <p>Cantidad</p>
      <input type="number" v-model="nuevoGasto.cantidad" class="form-control">

      <div class="text-center m-2">
        <LoadingButton @clicked="crearGasto" :iconProp="'fas fa-paper-plane'" :loadingProp="loadingNuevo" :classProp="'btn btn-outline-info'" :textProp="'Crear'" class="m-1"></LoadingButton>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import LoadingButton from '../components/LoadingButton.vue'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        loadingNuevo: false,
        options: [],
        nuevoGasto: {
          fecha: new Date().toISOString().slice(0,10),
          concepto: null,
          cantidad: null
        }
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      async crearGasto() {
        try {
          this.nuevoGasto.cantidad = Number(this.nuevoGasto.cantidad)
          this.loadingNuevo = true
          const res = await axios.post(config.apiUrl + '/gastos/nuevo', this.nuevoGasto)
          this.loadingNuevo = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$emit('nuevo-gasto')
        } catch (e) {
          this.loadingNuevo = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>
