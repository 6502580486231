<template>
  <div>
    <h1 class="mt-4 mb-4">Gastos</h1>

    <div class="row">
      <div class="col-sm-auto">
        <button type="button" @click="showNuevoGasto = !showNuevoGasto" class="btn m-1 btn-outline-primary"><i class="fas fa-plus"></i> Nuevo</button>
      </div>
    </div>

    <NuevoGasto v-show="showNuevoGasto" @nuevo-gasto="nuevoGasto"></NuevoGasto>

    <p v-show="errorGastos">Error obteniendo los gastos</p>

    <h3 v-show="loadingGastos" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!errorGastos && !loadingGastos" class="text-center">
      <p class="mt-3"><strong>{{ gastos.length }}</strong> gasto/s</p>

      <div v-for="gasto in gastos" :key="gasto._id" class="row m-3 shadow text-center align-items-center border bg-light">

        <div class="col-sm">
          <div class="card-body">
            <h5><i class="fas fa-calendar-day text-primary"></i> {{ new Date(gasto.fecha).toLocaleDateString() }}</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <h5 class="text-capitalize"><i class="fas fa-money-check-alt text-success"></i> {{ gasto.concepto }}</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <h5><i class="fas fa-coins text-warning"></i> {{ gasto.cantidad }} €</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <LoadingButton @clicked="eliminarGasto(gasto._id)" :iconProp="'fas fa-trash-alt'" :loadingProp="loadingEliminarGasto" :classProp="'btn btn-danger'"></LoadingButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import NuevoGasto from '../components/NuevoGasto.vue'
  import LoadingButton from '../components/LoadingButton.vue'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        gastos: [],
        loadingGastos: true,
        loadingEliminarGasto: false,
        errorGastos: false,
        showNuevoGasto: false,
        options: [],
        selectedOption: null
      }
    },
    components: {
      NuevoGasto,
      LoadingButton
    },
    methods: {
      async getGastos() {
        try {
          this.loadingGastos = true
          const res = await axios.get(config.apiUrl + '/gastos')
          this.loadingGastos = false
          this.gastos = res.data
        } catch (e) {
          this.loadingGastos = false
          this.errorGastos = true
        }
      },
      async eliminarGasto(id) {
        try {
          this.loadingEliminarGasto = true
          const res = await axios.post(config.apiUrl + '/gastos/eliminar', {
            id
          })
          this.loadingEliminarGasto = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.getGastos()
        } catch (e) {
          this.loadingEliminarGasto = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      nuevoGasto() {
        this.showNuevoGasto = false
        this.getGastos()
      },
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getGastos()
    }
  }
</script>
